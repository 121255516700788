<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT LOẠI SẢN PHẨM" : "THÊM LOẠI SẢN PHẨM"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="label-form">Mã loại sản phẩm</div>
              <v-text-field
                v-model="form.ma"
                placeholder="Mã loại sản phẩm"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="codeRules"
                :disabled="edit ? true : false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Tên loại sản phẩm</div>
              <v-text-field
                v-model="form.ten"
                placeholder="Tên nhóm"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import { addProductTypes, editProductTypes } from "@/api/loaisanpham";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";
export default {
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      ma: null,
      ten: null,
      description: null,
    },
    nameRules: [(v) => !!v || "Tên loại sản phẩm không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã loại sản phẩm không thể bỏ trống"],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        ma: null,
        ten: null,
        description: null,
      };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
      console.log(this.form);
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          const res = await loaiChatThaiAPI.create(this.form);
          this.show = false;
          this.btnLoading = false;
          if (res?.isDone) {
            this.$emit("on-done");
          }
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        console.log('form', this.form);
        try {
          const res = await loaiChatThaiAPI.update(this.form.id, this.form);
          this.show = false;
          this.btnLoading = false;
          if (res?.isDone) {
            this.$emit("on-done");
          }
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
