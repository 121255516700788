<template>
  <PageListLayout
    noFilter
    icon="mdi-view-list"
    title="Loại sản phẩm"
    subTitle="Danh sách loại sản phẩm"
    useAdd
    titleAdd="Tạo mới loại sản phẩm"
    @add="createItem"
  >
    <template slot="filter">
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa loại sản phẩm</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit ref="form" @on-done="getAllList"></create-edit>
  </PageListLayout>
</template>
<script>
// import { getProductTypes, deleteProductTypes } from "@/api/loaisanpham";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";
import CreateEdit from "./create-edit";
import { debounce } from "lodash";

export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        {
          text: "Mã sản phẩm",
          align: "start",
          sortable: false,
          value: "ma",
        },
        { text: "Tên sản phẩm", value: "ten" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getAllList();
  },
  watch: {
    search: debounce((val) => {
      this.getAllList();
    }, 300),
  },
  methods: {
    async getAllList() {
      this.loading = true;
      let data = await loaiChatThaiAPI.list({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa loại sản phẩm ",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa loại sản phẩm " +
          "<strong>" +
          item.ten +
          " ?" +
          "</strong>",
        action: () => loaiChatThaiAPI.destroy(item.id),
        onDone: this.getAllList,
      });
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
  },
};
</script>
